import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  Grid,
  Tab,
  Tabs,
  TextField,
  withStyles,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Autocomplete } from "@material-ui/lab";

import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import * as histogram from "highcharts/modules/histogram-bellcurve";

import { generateDefaultChartOptions } from "../../utils";

import { useApp } from "../../hooks/useApp";
import { useCacheBuster } from "../../hooks/CacheBuster";
import { useQuery } from "../../hooks/useQuery";
import { useTheme } from "../../hooks/useTheme";

highcharts3d(Highcharts);
histogram(Highcharts);

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tabs: {
    padding: theme.spacing(2),
  },
  logoImage: {
    paddingTop: 15,
    width: 120,
    height: 100,
  },
  homeIcon: {
    fontSize: 50,
    paddingTop: 20,
  },
  section: {
    padding: theme.spacing(2),
  },
  sectionContent: { padding: theme.spacing(3), height: "100%" },
  graphCardContent: { padding: theme.spacing(3) },
  cardTitle: {
    fontSize: 22 + theme.offset,
    color: theme.palette.text.primary,
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div {...other}>
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    display: "none",
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontSize: 24 + theme.offset,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: theme.palette.common.white,
      opacity: 1,
      borderRadius: 5,
      backgroundColor: "gray",
    },
    "&$selected": {
      color: "#fff",
      fontWeight: theme.typography.fontWeightMedium,
      borderRadius: 5,
      backgroundColor: "gray",
    },
    "&:focus": {
      color: theme.palette.primary.white,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const Home = ({ history }) => {
  const classes = useStyles();
  const query = useQuery();
  const { appData } = useApp();
  const { darkState, textOffset } = useTheme();
  const { refreshCacheAndReload } = useCacheBuster();

  const tipoConsejo = query.get("tipo") || 0;

  const [value, setValue] = React.useState(parseInt(tipoConsejo));

  const fecha = appData.fecha;

  const dataEstatal = appData.gubernatura[0];

  const dataDistrital = appData.distrital.sort(
    (a, b) =>
      parseInt(a.consejo.nombre.split(" ")[2]) -
      parseInt(b.consejo.nombre.split(" ")[2])
  );

  const dataMunicipal = appData.municipal.sort((a, b) =>
    a.consejo.nombre.toUpperCase().split(" ")[3] <
    b.consejo.nombre.toUpperCase().split(" ")[3]
      ? -1
      : 1
  );

  const actasTotales = dataEstatal.porComputar + dataEstatal.computadas;

  let chartOptions = generateDefaultChartOptions("column");
  let chartOptions2 = generateDefaultChartOptions("column");

  const onRefreshPressed = async () => {
    try {
      await refreshCacheAndReload();
    } catch (e) {
      console.log(e);
    }
  };

  function getData(data) {
    const d = data.map(function (participantePolitico, i) {
      return {
        name: participantePolitico[0],
        y: participantePolitico[1],
        logo: dataEstatal.computo[i].logo,
      };
    });

    return [
      ...d,
      {
        name: "Candidatos No Registrados",
        y: dataEstatal.totalVotosCandidatosNoRegistrados,
      },
      { name: "Votos Nulos", y: dataEstatal.totalVotosNulos },
    ];
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let extraOptions = {
    title: {
      text: dataEstatal.consejo.nombre,
      style: {
        color: darkState ? "#fff" : "#000",
        fontSize: 18 + textOffset,
      },
      align: "left",
    },
    legend: {
      enabled: false,
    },
    subtitle: {
      text: `TOTAL DE VOTOS: ${dataEstatal.totalVotos}`,
      style: {
        color: darkState ? "#fff" : "#000",
        fontSize: 20 + textOffset,
      },
      align: "left",
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: " ",
      },
    },
    xAxis: {
      type: "category",
      labels: {
        useHTML: true,
        animate: true,
        formatter: function () {
          var value = this.value;
          var output = "";
          var percentage = "";

          dataEstatal.computo.forEach(function (p) {
            if (value === p.nombre) {
              output = p.logo;
              percentage = p.porcentaje.toFixed(2);
            } else if (value === "Candidatos No Registrados") {
              output = "noRegistrados.png";
              percentage = (
                (dataEstatal.totalVotosCandidatosNoRegistrados /
                  dataEstatal.totalVotos) *
                100
              ).toFixed(2);
            } else if (value === "Votos Nulos") {
              output = "votosNulos.png";
              percentage = (
                (dataEstatal.totalVotosNulos / dataEstatal.totalVotos) *
                100
              ).toFixed(2);
            }
          });

          return (
            '<span><img src="' +
            `/${output}?fecha=${new Date()}` +
            '" style="width: 40px; height: auto;"/><br></span>' +
            `<h2 style="color: ${darkState ? "#fff" : "#000"}">${
              isNaN(percentage) ? 0 : percentage
            }%</h2>`
          );
        },
      },
    },
    series: [
      {
        dataLabels: [
          {
            enabled: true,
            inside: true,
            style: {
              fontSize: 22 + textOffset,
              color: darkState ? "#fff" : "#fff",
            },
          },
        ],
        colors: ["#cd5d23"],
        colorByPoint: true,
        name: "Resultados Preliminares",
        data: getData(
          dataEstatal.computo.map((p) => [p.nombre, p.votos]).slice()
        ),
      },
    ],
    exporting: {
      allowHTML: true,
    },
  };

  let extraOptions2 = {
    title: {
      text: dataEstatal.consejo.nombre,
      style: {
        color: darkState ? "#fff" : "#000",
        fontSize: 18 + textOffset,
      },
      align: "left",
    },
    legend: {
      enabled: false,
    },
    subtitle: {
      text: `TOTAL DE ACTAS: ${actasTotales}`,
      style: {
        color: darkState ? "#fff" : "#000",
        fontSize: 20 + textOffset,
      },
      align: "left",
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: " ",
      },
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -10,
        allowOverlap: true,
        style: {
          wordBreak: "break-all",
          textOverflow: "allow",
          color: darkState ? "#fff" : "#000",
          fontSize: 22 + textOffset,
        },
      },
      categories: ["Computadas", "Por Computar"],
    },
    series: [
      {
        dataLabels: [
          {
            enabled: true,
            inside: true,
            style: {
              fontSize: 22 + textOffset,
              color: darkState ? "#fff" : "#fff",
            },
          },
        ],
        colors: ["#cd5d23", "#9c3c0b"],
        colorByPoint: true,
        name: "Seguimiento de Cómputo",
        data: [
          ["Computadas", dataEstatal.computadas],
          ["Por Computar", dataEstatal.porComputar],
        ],
      },
    ],
  };

  chartOptions = { ...chartOptions, ...extraOptions };
  chartOptions2 = { ...chartOptions2, ...extraOptions2 };

  chartOptions.chart.backgroundColor = darkState ? "#545250" : "#fff";
  chartOptions.chart.scrollablePlotArea = {
    minWidth: 1500,
  };
  chartOptions2.chart.backgroundColor = darkState ? "#545250" : "#fff";

  const FirstTab = () => {
    return (
      <TabPanel value={value} index={0}>
        <Grid container>
          <Grid item md={8} xs={6} className={classes.section}>
            <Grid container component={Card} className={classes.sectionContent}>
              <Grid item xs={12}>
                <Typography className={classes.cardTitle}>
                  <strong>Último Corte:</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.cardTitle}>{fecha}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} xs={6} className={classes.section}>
            <Grid container component={Card} className={classes.sectionContent}>
              <Button onClick={onRefreshPressed}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography align="center" className={classes.cardTitle}>
                      <RefreshIcon />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="center">ACTUALIZAR</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>

          <Grid item lg={12} xs={12} className={classes.section}>
            <Grid
              container
              component={Card}
              className={classes.graphCardContent}
            >
              <Grid item xs={12}>
                <Typography className={classes.cardTitle} align="center">
                  RESULTADOS DE LA VOTACIÓN
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.section}>
            <Grid
              container
              component={Card}
              className={classes.graphCardContent}
            >
              <Grid item xs={12}>
                <Typography className={classes.cardTitle} align="center">
                  SESIÓN DE CÓMPUTO
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions2}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
    );
  };

  const SecondTab = () => {
    return (
      <TabPanel value={value} index={1}>
        <Grid container>
          <Grid item md={8} xs={10} style={{ padding: 20 }}>
            <Autocomplete
              id="Distrital"
              fullWidth
              options={dataDistrital}
              onChange={(e, v) =>
                history.push(
                  `/detalle?id=${v.consejo.idConsejoElectoral}&tipo=${value}`
                )
              }
              getOptionLabel={(option) => option.consejo.nombre}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccione el consejo distrital"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </TabPanel>
    );
  };

  const ThirdTab = () => {
    return (
      <TabPanel value={value} index={2}>
        <Grid container>
          <Grid item md={8} xs={10} style={{ padding: 20 }}>
            <Autocomplete
              id="Municipal"
              fullWidth
              options={dataMunicipal}
              onChange={(e, v) =>
                history.push(
                  `/detalle?id=${v.consejo.idConsejoElectoral}&tipo=${value}`
                )
              }
              getOptionLabel={(option) => option.consejo.nombre}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccione el consejo municipal"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </TabPanel>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={1}>
              <Button fullWidth onClick={() => setValue(0)}>
                <Typography>
                  <HomeIcon className={classes.homeIcon} />
                </Typography>
              </Button>
            </Grid>
            <Grid item md={8} xs={11}>
              <AntTabs
                value={value}
                className={classes.tabs}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <AntTab label="Gubernatura" {...a11yProps(0)} />
                <AntTab label="Diputaciones" {...a11yProps(1)} />
                <AntTab label="Ayuntamientos" {...a11yProps(2)} />
              </AntTabs>
            </Grid>
            <Grid item md={3} xs={12}>
              <Grid container justify="center">
                <img
                  src="iee_logo.png"
                  alt="Logo"
                  className={classes.logoImage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <FirstTab />
            </Grid>
            <Grid item xs={12}>
              <SecondTab />
            </Grid>
            <Grid item xs={12}>
              <ThirdTab />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default Home;
