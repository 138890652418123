import React from "react";

import { CssBaseline, ThemeProvider as Theme } from "@material-ui/core";

import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";

import FullScreenLoader from "./components/FullScreenLoader";

import { AppProvider, useApp } from "./hooks/useApp";
import { ThemeProvider, useTheme } from "./hooks/useTheme";
import { CacheBusterProvider } from "./hooks/CacheBuster";
import { AuthProvider } from "./hooks/useAuth";

const App = () => {
  const RootComponent = () => {
    const { appData } = useApp();
    const { MuiTheme } = useTheme();

    if (!appData) return <FullScreenLoader />;

    return (
      <Theme theme={MuiTheme}>
        <CssBaseline />
        <PrivateRoutes />
      </Theme>
    );
  };

  return (
    <CacheBusterProvider>
      <AppProvider>
        <AuthProvider>
          <ThemeProvider>
            <RootComponent />
          </ThemeProvider>
        </AuthProvider>
      </AppProvider>
    </CacheBusterProvider>
  );
};

export default App;
