import React from "react";
import { NavLink } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  Grid,
  Switch,
  Tooltip,
} from "@material-ui/core";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import { ExitToApp } from "@material-ui/icons";

import { useTheme } from "../hooks/useTheme";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 100,
  },
  paper: {
    width: 300,
    background: "#b5b5b5",
  },
  icon: { color: "#fff", fontSize: 22 },
  header: {
    height: 75,
  },
  signOut: {
    marginLeft: 14,
    cursor: "pointer",
    color: "#fff",
    fontSize: 22,
  },
}));

function Header() {
  const classes = useStyles();
  const { darkState, setDarkState, setTextOffset } = useTheme();

  const homeLogo = (
    <NavLink to="/">
      <img src="logo_iee_full.png" alt="logo" className={classes.image} />
    </NavLink>
  );

  const displayDesktop = () => {
    return (
      <Toolbar className={classes.toolbar}>
        <Grid item xs={12}>
          {homeLogo}
        </Grid>
        <Tooltip
          placement="top"
          title="Tamaño de texto"
          arrow
          children={
            <>
              <Button
                onClick={() => {
                  setTextOffset((o) => o - 1);
                }}
              >
                -
              </Button>
              <SortByAlphaIcon className={classes.icon} />
              <Button
                onClick={() => {
                  setTextOffset((o) => o + 1);
                }}
              >
                +
              </Button>
            </>
          }
        />
        <Tooltip
          placement="bottom"
          title="Prender/apagar modo oscuro"
          arrow
          children={
            <Switch
              onClick={() => setDarkState((s) => !s)}
              checked={darkState}
              icon={<WbSunnyIcon className={classes.icon} />}
              checkedIcon={<NightsStayIcon className={classes.icon} />}
            />
          }
        />
        {/* <Tooltip
          placement="bottom"
          title="Cerrar sesión"
          arrow
          children={<ExitToApp onClick={signOut} className={classes.signOut} />}
        /> */}
      </Toolbar>
    );
  };

  return (
    <AppBar className={classes.header} position="static">
      {displayDesktop()}
    </AppBar>
  );
}

export default Header;
