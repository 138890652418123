const capitalizeFirst = (string) => {
  const split = string.split("-");
  let palabraUnida = "";
  split.forEach((s) => {
    palabraUnida = palabraUnida + s.charAt(0).toUpperCase() + s.slice(1);
  });
  return palabraUnida;
};
const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validateRfc = (rfcString) => {
  let re =
    /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/i;
  return re.test(String(rfcString).toLowerCase());
};

const validateName = (name) => {
  let re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
  return re.test(name);
};

const validateUser = (user) => {
  let re = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/;
  return re.test(user);
};

const validatePassword = (passwd) => {
  let re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  return re.test(passwd);
};

const validateConfirmPassword = (passwd, passwd2) => {
  if (passwd2 !== passwd) return false;
  else return true;
};

const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
};

const propertyAccesor = (rootObj, accesor = "") => {
  const properties = accesor.split(".");
  let tmp = rootObj;
  properties.forEach((prop) => (tmp = tmp[prop]));
  return tmp;
};

const generateDefaultChartOptions = (chartType = "pie", options = {}) => ({
  chart: {
    type: chartType,
    inverted: options.inverted || false,
    options3d: {
      enabled: chartType === "pie",
      alpha: 45,
      beta: 0,
    },
    height: options.chartHeight || null,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: options.title || "TITULO POR DEFAULT",
  },
  subtitle: {
    text: options.subtitle || "SUBTITULO POR DEFAULT",
  },
  plotOptions: {
    pie: {
      innerSize: 100,
      depth: 45,
    },
  },
  series: [
    {
      name: options.name || "NOMBRE DE LA COLECCION DE DATOS",
      data: options.data || [],
    },
  ],
});

const getPercentage = (firstNum, secondNum) => {
  var result = (firstNum / secondNum) * 100;
  return `${result.toFixed(2)}%`;
};

const formatDate = (date) => {
  const tmpDate = new Date(date);
  const formattedDate = new Date(
    tmpDate.setHours(tmpDate.getHours() - 7)
  ).toLocaleString("es-MX");
  return formattedDate;
};

export {
  capitalizeFirst,
  validateEmail,
  validateRfc,
  validateName,
  validateUser,
  validatePassword,
  validateConfirmPassword,
  formatPhoneNumber,
  propertyAccesor,
  generateDefaultChartOptions,
  getPercentage,
  formatDate,
};
